import { Drawer, Menu, Toggle } from "react-daisyui";

const ConfigDrawer = ({drawerStatus, handleDrawerOpen, handleEnableCamera, cameraEnable}) => {

    return (
    <Drawer open={drawerStatus} side={
        <Menu className="p-2 w-80 h-full bg-base-200 text-base-content z-50">
        <Menu.Title>Configuration</Menu.Title>
        <Menu.Item>Enable Vision: <Toggle color="primary" checked={cameraEnable}  onChange={handleEnableCamera}/></Menu.Item>
    </Menu>
    } onClickOverlay={handleDrawerOpen} className="z-50">
    </Drawer>)

}

export default ConfigDrawer