


const RecognitionIndicator = () => {
    return (
        <div className="absolute top-0 mt-2 ml-2 flex items-center">
            <div className="w-3 h-3 bg-white rounded-full animate-pulse"></div>
            <span className="ml-2 text-white">Listening...</span>
        </div>
    )

}

export default RecognitionIndicator;